import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueAxios from "vue-axios";
import axios from "axios";
import Select2 from "vue3-select2-component";
import VueToast from 'vue-toast-notification';
import VueQRCodeComponent from 'vue-qrcode-component';

import 'vue-toast-notification/dist/theme-sugar.css';
import "./assets/css/bea.css";
import "./assets/css/mdi.css";
import "./assets/css/customStyle.css";
const app = createApp(App).use(store).use(router);
app.use(VueAxios, axios);
app.component("Select2", Select2);
app.use(VueToast);
app.component('qr-code', VueQRCodeComponent);

app.mount("#app");
