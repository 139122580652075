import { createStore } from "vuex";

export default createStore({
  state: {
    loader: 0,
    // BaseApiURL: "https://islamicvaluesforum.manialab.sa/api/",
    // BaseApiURL: "https://qeyam.moia.gov.sas/api/",
    BaseApiURL: "https://qeyams.com/api/",
    emailRegex: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
